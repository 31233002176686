import { useNavigate } from 'react-router-dom'

import IconButton from 'src/components/button/IconButton'
import Heading from 'src/components/heading/Heading'
import { I18N_HELP_FORM } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { useViewport } from 'src/contexts/viewportContext'

import styles from './helpRoute.module.scss'

const HelpRouteHeader = () => {
  const { isDesktop } = useViewport()
  const navigate = useNavigate()

  return (
    <div className={styles.heading}>
      {!isDesktop && (
        <IconButton
          id={ID.topBarBackButton}
          iconName='arrow-left'
          onClick={() => navigate(-1)}
        />)
      }
      <Heading level='h1' label={`${I18N_HELP_FORM}.title`} className={'desktop-h4 mobile-h5'} />
    </div>
  )
}

export default HelpRouteHeader
