import { lazy, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import Loader from 'react-spinners/CircleLoader'

import { RegularButtonTypes } from 'src/components/button/RegularButton'
import EmptyStateMessage from 'src/components/emptyStateMessage/EmptyStateMessage'
import Heading from 'src/components/heading/Heading'
import MobileTopBar from 'src/components/mobileTopBar/MobileTopBar'
import ModerationWrapper from 'src/components/moderation/ModerationWrapper'
import SWRErrorBoundary from 'src/components/swr/SWRErrorBoundary'
import TabBarLayout from 'src/components/tabBarLayout/TabBarLayout'
import { I18N_GLOBAL, I18N_USER, I18N_USER_HIDE_OFFERS, I18N_USER_NO_OFFERS } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL, { YomeHash } from 'src/constants/navigate.constants'
import SWRkeys from 'src/constants/swr.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { useGetValueFromUrlParam } from 'src/hooks/query.hooks'
import { ICurrentUser, UserRoles } from 'src/models/currentUser.model'
import { getUserNavLink } from 'src/models/navigation.model'
import { SORT_QPARAM } from 'src/models/search.model'
import { SortPostListTypes } from 'src/models/sort.model'
import { useCurrentUser } from 'src/services/currentUser.service'
import { useGetUser, useGetUserPostList } from 'src/services/user.service'

import NotFoundRoute from '../notFound/NotFoundRoute'
import UserInfo from './info/UserInfo'

import styles from './user.module.scss'

const UserModeration = lazy(() => import('src/components/moderation/UserModeration'))

interface IUserLayoutProps {
  userId: string;
  swrKey: string;
  currentUser: ICurrentUser | undefined;
  mutateCurrentUser: () => void;
}

const getEmptyStateProps = (isCurrentUser: boolean, isPhoneVerified: boolean) => {
  if (isPhoneVerified) {
    return {
      title: `${I18N_USER_NO_OFFERS}.title`,
      text: `${I18N_USER_NO_OFFERS}.description`,
      icon: 'offers-search',
      button: {
        id: ID.notFoundLinkToMainPage,
        buttonType: RegularButtonTypes.blackNoBorder,
        label: `${I18N_GLOBAL}.toHome`,
        linkTo: YomeURL.mainPage,
      },
    }
  }

  if (isCurrentUser) {
    return {
      title: `${I18N_USER_HIDE_OFFERS}.verifyPhone.title`,
      text: `${I18N_USER_HIDE_OFFERS}.verifyPhone.description`,
      icon: 'searchNoPosts',
      button: {
        id: ID.notFoundLinkToMainPage,
        buttonType: RegularButtonTypes.blackNoBorder,
        label: `${I18N_USER_HIDE_OFFERS}.verifyPhone.button`,
        linkTo: YomeHash.verifyPhone,
      },
    }
  }

  return {
    title: `${I18N_USER_HIDE_OFFERS}.loginToSee.title`,
    text: `${I18N_USER_HIDE_OFFERS}.loginToSee.description`,
    icon: 'searchNoPosts',
    button: {
      id: ID.notFoundLinkToMainPage,
      buttonType: RegularButtonTypes.blackNoBorder,
      label: `${I18N_GLOBAL}.join`,
      linkTo: YomeHash.auth,
    },
  }
}

const UserLayout = ({ userId, swrKey, currentUser, mutateCurrentUser }: IUserLayoutProps) => {
  const { data: user, isLoading: isOffersLoading } = useGetUser(SWRkeys.getUser(userId))
  const { data: userOffers, isLoading: isUserLoading } = useGetUserPostList(swrKey)
  const { t } = useTranslation()

  const activeHash = useLocation().hash

  const { isDesktop } = useViewport()

  // refetch current user on hash change - hash changes on verify modal open/close
  useEffect(() => {
    mutateCurrentUser()
  }, [activeHash, mutateCurrentUser])

  if (isOffersLoading || isUserLoading) {
    return (
      <Loader />
    )
  }

  if (!user || !userOffers) {
    return <NotFoundRoute />
  }

  const userHasOffers = userOffers.active.length || userOffers.inactive.length

  const isPhoneVerified = currentUser?.isPhoneVerified

  const isDisplayEmptyState = !userHasOffers || !isPhoneVerified || !currentUser

  const emptyStateProps = getEmptyStateProps(!!currentUser, !!isPhoneVerified)

  return (
    <>
      {!isDesktop && <MobileTopBar/>}
      <div className={styles.container}>
        <UserInfo user={user}/>
        { !isDisplayEmptyState ? (
          <TabBarLayout
            title={t(`${I18N_USER}.sellerOffers`)}
            tabItems={getUserNavLink(userId, userOffers)}
          />
        ) : (
          <div className={styles.noOffersContainer}>
            <Heading
              level='h1'
              label={`${I18N_USER}.sellerOffers`}
              className={'desktop-h4 mobile-h5'}
            />
            <EmptyStateMessage
              {...emptyStateProps}
            />
          </div>)
        }
      </div>
    </>
  )
}

const UserLayoutBoundary = () => {
  const { userId } = useParams()
  const sortBy = useGetValueFromUrlParam(SORT_QPARAM, SortPostListTypes.newest)
  const offerListKey = SWRkeys.userOffers(userId!, sortBy)

  const { data: currentUser, mutate: mutateCurrentUser } = useCurrentUser()

  const isModerator = currentUser?.roles?.includes(UserRoles.moderator)

  return (
    <SWRErrorBoundary swrKey={SWRkeys.getUser(userId!)}>
      <SWRErrorBoundary swrKey={offerListKey}>
        <UserLayout
          userId={userId!}
          swrKey={offerListKey}
          currentUser={currentUser}
          mutateCurrentUser={mutateCurrentUser}
        />
        {isModerator && (
          <ModerationWrapper>
            <UserModeration userId={userId!}/>
          </ModerationWrapper>
        )}
      </SWRErrorBoundary>
    </SWRErrorBoundary>
  )
}

export default UserLayoutBoundary
