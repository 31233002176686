import classNames from 'classnames'
import { t } from 'i18next'

import { I18N_POST } from 'src/constants/i18n.constants'
import { MAX_ITEMS_IN_ROW } from 'src/constants/newOffer.constants'
import { useViewport } from 'src/contexts/viewportContext'

import FoundCategoryCardsSet from '../foundCategoryCardsSet/FoundCategoryCardsSet'

import styles from './categorySearchResults.module.scss'

interface ICategorySearchResultsProps {
  categoryPathList: string[][];
  submitCategory: (categoryPath: string[]) => void;
  searchFieldName: string;
}

const CategorySearchResults = ({
  categoryPathList,
  submitCategory,
  searchFieldName,
}: ICategorySearchResultsProps) => {
  const { isDesktop } = useViewport()

  const isTwoColumns = categoryPathList.length >= MAX_ITEMS_IN_ROW

  return (
    <div>
      {(!categoryPathList.length) && (
        <p className={classNames('label-18', styles.info)}>
          <span>
            🔎
          </span>
          {t(`${I18N_POST}.${'notFound'}`)}
        </p>)
      }

      {isDesktop && isTwoColumns
        ? (
          <div className={styles.double}>
            <div className={styles.list}>
              <FoundCategoryCardsSet
                categoryPathList={categoryPathList.slice(0, MAX_ITEMS_IN_ROW)}
                submitCategory={submitCategory}
              />
            </div>
            <div className={styles.list}>
              <FoundCategoryCardsSet
                searchFieldName={searchFieldName}
                categoryPathList={categoryPathList.slice(MAX_ITEMS_IN_ROW)}
                submitCategory={submitCategory}
              />
            </div>
          </div>
        )
        : (
          <div className={styles.list}>
            <FoundCategoryCardsSet
              searchFieldName={searchFieldName}
              categoryPathList={categoryPathList}
              submitCategory={submitCategory}
            />
          </div>)
      }
    </div >
  )
}

export default CategorySearchResults
