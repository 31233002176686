import classNames from 'classnames'
import { memo, useCallback } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'

import PostAvatar from 'src/components/avatar/PostAvatar'
import DateComponent from 'src/components/date/DateComponent'
import OnlineStatus from 'src/components/onlineStatus/OnlineStatus'
import YomeURL from 'src/constants/navigate.constants'
import { DateFormatType } from 'src/models/date.model'
import { IChat } from 'src/models/messenger.model'
import { AvatarSizes } from 'src/models/upload.model'
import { CHAT_ID_SEARCH_PARAM } from 'src/services/messenger.service'

import styles from './chatDisplay.module.scss'

type IChatDisplayProps = {
  chat: IChat;
  isSelected: boolean;
}

const ChatDisplay = ({
  chat: { id, offer, receiverInfo, lastMessageCreatedAt, lastMessage, isReceiverOnline, unreadMessagesCount },
  isSelected = false,
}: IChatDisplayProps) => {
  const navigate = useNavigate()

  const handlePreviewClick = useCallback(() => {
    navigate({
      pathname: YomeURL.messages,
      search: createSearchParams({ [CHAT_ID_SEARCH_PARAM]: id }).toString(),
    })
  }, [navigate, id])

  return (
    <div
      className={classNames(styles.main, { [styles.selected]: isSelected })}
      onClick={handlePreviewClick}
    >
      <div className={styles.avatar}>
        <PostAvatar
          avatarType={AvatarSizes.tiny}
          image={offer.photo}
          folder={offer.publicId}
        />
      </div>
      <div className={styles.info}>
        <div className={styles.user}>
          <span className={styles.partner}>
            {receiverInfo.name}
          </span>
          {isReceiverOnline && <OnlineStatus />}
        </div>
        <span className={styles.offer}>
          {offer.title}
        </span>
        <span className={styles.message}>
          {lastMessage}
        </span>
      </div>
      <div className={styles.date}>
        <DateComponent isoTimestamp={lastMessageCreatedAt} type={DateFormatType.chatShort} />
        {!!unreadMessagesCount && (
          <div className={classNames('supportive-12', styles.counter)}>
            {Math.min(unreadMessagesCount, 99)}
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(ChatDisplay)
