import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import InfoButton, { InfoButtonSizes } from 'src/components/button/InfoButton/InfoButton'
import { useModalsContext } from 'src/contexts/openModalContext'
import { BadgeInfo, IUserBadgeInfo, UserBadgesEnum } from 'src/models/badge.models'
import { ContextModalType } from 'src/models/modal.model'

interface BadgeProps {
  badgeInfo: IUserBadgeInfo;
  hasIcon: boolean;
  badgeSize?: InfoButtonSizes;
  className?: string;
}

const BADGE_TYPE_TO_MODAL_TYPE = {
  [UserBadgesEnum.phoneVerified]: ContextModalType.verifiedPhone,
  [UserBadgesEnum.ecoContribution]: null,
  [UserBadgesEnum.documentsVerified]: null,
  [UserBadgesEnum.purchasesNumber]: null,
  [UserBadgesEnum.salesNumber]: null,
}

const Badge = ({ badgeInfo, hasIcon, badgeSize = InfoButtonSizes.large, className }: BadgeProps) => {
  const { t } = useTranslation()

  const badgeContext = BadgeInfo[badgeInfo.badgeName]

  const { setContextModal } = useModalsContext()

  const onBadgeClick = useCallback(() => {
    setContextModal({ modalType: BADGE_TYPE_TO_MODAL_TYPE[badgeInfo.badgeName], isNonFullscreen: true })
  }, [setContextModal, badgeInfo.badgeName])

  return (
    <InfoButton
      onClick={badgeSize === InfoButtonSizes.large ? onBadgeClick : undefined}
      label={t(`user.${badgeInfo.badgeName}.title`, { badgeMetric: badgeInfo.badgeMetric })}
      leftIcon={hasIcon ? badgeContext.icon : ''}
      buttonSize={badgeSize}
      className={className}
    />
  )
}

export default Badge
