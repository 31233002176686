import { createSearchParams } from 'react-router-dom'

import { PostListType } from 'src/models/post.model'
import { SORT_QPARAM } from 'src/models/search.model'
import { SortPostListTypes } from 'src/models/sort.model'

import {
  API_ADDRESS_HISTORY_PREFIX,
  API_AUTH_PREFIX,
  API_AUTH_USER_PREFIX,
  API_DRAFT_PREFIX,
  API_HELP_PREFIX,
  API_IMAGE_PREFIX,
  API_OFFER_PREFIX,
  API_PREFERENCES_PREFIX,
  API_PROFILE_PREFIX,
  API_REPORT_PREFIX,
  API_SEARCH_PREFIX,
  API_USER_PREFIX,
  API_VERIFY_PHONE_PREFIX,
} from './apiPrefixes.constants'

const YomeAPI = {
  // base api

  offerPublic: `${API_OFFER_PREFIX}/public`,
  offerProfile: `${API_OFFER_PREFIX}/profile`,
  recomendedOffers: `${API_OFFER_PREFIX}/recommended`,
  popularOffers: `${API_OFFER_PREFIX}/popular`,
  offerProfileCounters: `${API_OFFER_PREFIX}/profile/counters`,
  archiveOffer: `${API_OFFER_PREFIX}/archive`,
  publishOffer: (slug: string) => `${API_OFFER_PREFIX}/publish/${slug}`,
  republishOffer: (slug: string) => `${API_OFFER_PREFIX}/republish/${slug}`,
  deleteOffer: (slug: string) => `${API_OFFER_PREFIX}/${slug}`,
  validateOffer: (slug: string, partIndex: number) => `${API_OFFER_PREFIX}/validate/${slug}?part=${partIndex}`,
  updateOffer: (slug: string) => `${API_OFFER_PREFIX}/${slug}`,
  getOffer: (slug: string) => `${API_OFFER_PREFIX}/${slug}`,
  getPublicOffer: (slug: string) => `${API_OFFER_PREFIX}/public/${slug}`,
  getOfferOriginalText: (slug: string) => `${API_OFFER_PREFIX}/original-content/${slug}`,
  viewedOffers: (searchParams: Record<string, any>) => `${API_OFFER_PREFIX}/viewed?${createSearchParams(searchParams)}`,
  profileOfferList: (sortBy: SortPostListTypes, listType: PostListType) => (
    `${API_OFFER_PREFIX}/profile/${listType}?${createSearchParams({ [SORT_QPARAM]: sortBy })}`
  ),
  lastPublication: (searchParams: Record<string, any>) => (
    `${API_OFFER_PREFIX}/profile/${PostListType.active}?${createSearchParams(searchParams)}`
  ),

  getUserPreferences: `${API_PREFERENCES_PREFIX}`,
  updateUserPreferences: `${API_PREFERENCES_PREFIX}`,

  getUserAddressHistory: `${API_ADDRESS_HISTORY_PREFIX}`,

  createDraft: `${API_DRAFT_PREFIX}/create`,
  updateDraft: `${API_DRAFT_PREFIX}/update`,
  getDraft: (publicId: string) => `${API_DRAFT_PREFIX}/${publicId}`,
  setAutoRenewal: (slug: string) => `${API_OFFER_PREFIX}/auto-renewal/${slug}`,

  favoriteCounters: `${API_OFFER_PREFIX}/favorite/counters`,
  favoriteList: (searchParams: Record<string, any>) => (
    `${API_OFFER_PREFIX}/favorite?${createSearchParams(searchParams)}`
  ),
  favoriteOffer: (slug: string) => `${API_OFFER_PREFIX}/favorite/${slug}`,

  user: API_USER_PREFIX,
  getUser: (userId: string) => `${API_USER_PREFIX}/${userId}`,
  userOffers: (
    (userId: string, sortBy: SortPostListTypes) => `${API_USER_PREFIX}/post/${userId}?${createSearchParams({ sortBy })}`
  ),

  search: API_SEARCH_PREFIX,
  getSavedSearch: `${API_SEARCH_PREFIX}/saved-search`,
  searchWithParams: (searchParams: Record<string, any>) => `${API_SEARCH_PREFIX}?${createSearchParams(searchParams)}`,
  searchSimilar: (slug: string) => `${API_SEARCH_PREFIX}/similar/${slug}`,

  reportOffer: (offerId: string) => `${API_REPORT_PREFIX}/post/${offerId}`,

  help: API_HELP_PREFIX,
  getVerificationCode: `${API_HELP_PREFIX}/get-code`,
  getUsedEmails: `${API_HELP_PREFIX}/get-used-emails`,

  profile: API_PROFILE_PREFIX,

  sendVerifyPhoneSms: `${API_VERIFY_PHONE_PREFIX}/send-sms`,
  sendVerifyPhoneCall: `${API_VERIFY_PHONE_PREFIX}/send-call`,
  verifyPhoneCode: `${API_VERIFY_PHONE_PREFIX}/verify`,

  // images

  uploadOfferImages: `${API_IMAGE_PREFIX}/post/upload-photos`,
  deleteOfferImage: `${API_IMAGE_PREFIX}/post/delete-photos`,
  uploadAvatar: `${API_IMAGE_PREFIX}/avatar/upload-photo`,
  deleteAvatar: `${API_IMAGE_PREFIX}/avatar/delete-photo`,

  // auth

  authCurrentUser: `${API_AUTH_USER_PREFIX}/current`,
  authUpdateUsername: `${API_AUTH_USER_PREFIX}/username`,

  authSignup: `${API_AUTH_PREFIX}/signup`,
  authUserExists: `${API_AUTH_PREFIX}/user-exists`,
  authLogout: `${API_AUTH_PREFIX}/logout`,
  authLogin: `${API_AUTH_PREFIX}/login`,
  authResendVerifyEmail: `${API_AUTH_PREFIX}/resend-verify-email`,
  authResetPassord: `${API_AUTH_PREFIX}/reset-password`,
  authResendResetPassword: `${API_AUTH_PREFIX}/resend-reset-password`,
  authSetNewPassword: `${API_AUTH_PREFIX}/set-new-password`,
  authChangePassword: `${API_AUTH_PREFIX}/change-password`,
  authVerifyEmail: `${API_AUTH_PREFIX}/verify-email`,
}

export default YomeAPI
