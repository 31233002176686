import classNames from 'classnames'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import SvgIcon from '../svgIcon/SvgIcon'

import styles from './menu.module.scss'

export interface IMenuPointProps {
  onClick?: (event: React.MouseEvent) => void;
  id: string;
  label: string;
  linkTo?: string;
  isActive?: boolean;
  icon?: string;
  size?: MenuPointSize;
  iconRotate?: boolean;
  iconRight?: string;
}

export enum MenuPointSize {
  LARGE = 'large',
  SMALL = 'small',
}

const MenuPoint = ({
  id,
  label,
  linkTo,
  onClick,
  icon,
  isActive = false,
  size = MenuPointSize.SMALL,
  iconRotate,
  iconRight,
}: IMenuPointProps) => {
  const { t } = useTranslation()

  const customBtnStyles = classNames(styles.item, {
    [styles.active]: isActive,
    [styles.large]: size === MenuPointSize.LARGE,
  })

  const customIconStyles = classNames(styles.icon, {
    [styles.iconRotate]: iconRotate,
  })

  const Content = (
    <>
      <span className={styles.leftContent}>
        {icon && <SvgIcon name={icon} size={20} className={customIconStyles}/>}
        <span>
          {t(label)}
        </span>
      </span>
      {iconRight && <SvgIcon name={iconRight} size={20} />}
    </>)

  if (linkTo) {
    return (
      <li>
        <NavLink
          id={id}
          to={linkTo}
          className={customBtnStyles}
        >
          {Content}
        </NavLink>
      </li>)
  }

  return (
    <li>
      <button
        id={id}
        type='button'
        className={customBtnStyles}
        onClick={onClick}
      >
        {Content}
      </button>
    </li>
  )
}

export default memo(MenuPoint)
