import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { InfoButtonSizes } from 'src/components/button/InfoButton/InfoButton'

import RegularButton, { RegularButtonTypes } from '../../../../../components/button/RegularButton'
import { ID } from '../../../../../constants/id.constants'
import { IUserBadgeInfo } from '../../../../../models/badge.models'
import { useToggle } from '../../../../../utils/hooks.utils'
import Badge from './Badge'

import style from './badge.module.scss'

interface BadgeListProps {
  badgeList: IUserBadgeInfo[];
  hasBadgeIcon?: boolean;
  displayedBadgesNum?: number;
  showMoreButton?: boolean;
  badgeSize?: InfoButtonSizes;
  badgeClassName?: string;
}

const DEFAULT_DISPLAYED_BADGES = 3

const BadgeList = ({
  badgeList,
  hasBadgeIcon = false,
  displayedBadgesNum = DEFAULT_DISPLAYED_BADGES,
  showMoreButton = false,
  badgeSize = InfoButtonSizes.large,
  badgeClassName,
}: BadgeListProps) => {
  const { t } = useTranslation()

  const firstBadges = badgeList.slice(0, displayedBadgesNum)
  const remainBadges = badgeList.slice(displayedBadgesNum)
  const remainAmount = remainBadges.length

  const [isShowBadges, showMoreBadges] = useToggle()

  return (
    <div className={classNames(style.badgeWrapped, style[badgeSize])}>
      {firstBadges.map((badge) => (
        <Badge
          key={badge.badgeName}
          badgeInfo={badge}
          hasIcon={hasBadgeIcon}
          badgeSize={badgeSize}
          className={badgeClassName}
        />
      ))}
      {showMoreButton && remainAmount !== 0 && !isShowBadges && (
        <div className={style.showMoreBadges}>
          <RegularButton
            id={ID.userCardShowBadge}
            onClick={showMoreBadges}
            label=''
            labelI18n={t('user.showMoreBadges', { remainAmount })}
            buttonType={RegularButtonTypes.transparentNoBorderLight}
          />
        </div>
      )}
      {isShowBadges && remainBadges.map((badge) => (
        <Badge
          key={badge.badgeName}
          hasIcon={hasBadgeIcon}
          badgeInfo={badge}
          badgeSize={badgeSize}
          className={badgeClassName}
        />
      ))}
    </div>
  )
}

export default BadgeList
