import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import Paragraph from 'src/components/paragraph/Paragraph'
import { I18N_HELP_FORM_SUCCESS } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import ModalLayout from 'src/components/modal/ModalLayout'
import InfoModal from 'src/components/modal/infoModal/InfoModal'

import styles from './helpRoute.module.scss'

const HelpRouteResponseSuccess = () => {
  const modalFooterButton = (
    <RegularButton
      id={ID.supportPageLinkToMainPage}
      buttonType={RegularButtonTypes.blackNoBorder}
      label={`${I18N_HELP_FORM_SUCCESS}.toMainBtn`}
      linkTo={YomeURL.mainPage}
    />)

  return (
    <ModalLayout>
      <InfoModal
        topIcon='plane'
        title={`${I18N_HELP_FORM_SUCCESS}.title`}
        modalFooter={modalFooterButton}
        className={styles.successWrapper}
      >
        <Paragraph
          className='bodySizeL'
          text={`${I18N_HELP_FORM_SUCCESS}.text`}
        />
      </InfoModal>
    </ModalLayout>
  )
}

export default HelpRouteResponseSuccess
