import { useRef } from 'react'

import IconButton from 'src/components/button/IconButton'
import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import { I18N_CATEGORIES } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { useToggle } from 'src/utils/hooks.utils'
import { ANY_CATEGORY } from 'src/yome-categories-fields-config/category-config/categoriesTree'

import CategorySearchDropdown from './CategorySearchDropdown'

import styles from './categorySearchDropdown.module.scss'
import MobileCategoryMenu from './MobileCategoryMenu'

const CategorySearchButton = () => {
  const [isDropdownVisible, toggleDropdown] = useToggle()

  const { isDesktop } = useViewport()

  const buttonWrapperRef = useRef<HTMLDivElement | null>(null)

  const lefIconName = isDropdownVisible ? 'close-cross' : 'menu'

  return (
    <div className={styles.dropdown}>
      <div className={styles.categoryButton} ref={buttonWrapperRef}>
        {isDesktop ? (
          <RegularButton
            id={ID.searchBarSelectCategory}
            buttonType={RegularButtonTypes.blackNoBorder}
            onClick={toggleDropdown}
            label={`${I18N_CATEGORIES}.${ANY_CATEGORY}`}
            leftIcon={{ name: lefIconName, size: 24, className: styles.buttonIcon }}
          />
        ) : (
          <IconButton
            id={ID.searchBarSelectCategory}
            onClick={toggleDropdown}
            iconName='menu'
          />
        )}
      </div>
      {isDropdownVisible && (
        isDesktop ? (
          <CategorySearchDropdown
            name='category'
            toggleDropdown={toggleDropdown}
            isDropdownVisible={isDropdownVisible}
            buttonRef={buttonWrapperRef}
          />
        ) : (
          <MobileCategoryMenu
            name='category'
            toggleDropdown={toggleDropdown}
          />
        ))
      }
    </div>
  )
}

export default CategorySearchButton
