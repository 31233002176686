import { generateIdWithPrefix, PrefixAction } from 'src/constants/id.constants'
import { useFavorite } from 'src/hooks/favorite.hooks'
import { OfferStatus } from 'src/models/offerStatus.model'

import FavoriteRoundedControl from '../roundedControls/FavoriteRoundedControl'
import ShortenOfferCard, { ShortenOfferCardProps } from './ShortenOfferCard'

import styles from './shortenOfferCard.module.scss'

interface ShortenOfferCardWithLikeProps extends ShortenOfferCardProps {
  offerSourceEndpoint: string;
  hasAllFavorite: boolean;
}

const ShortenOfferCardWithLike = (
  {
    idPrefix,
    offer,
    offerSourceEndpoint,
    hasAllFavorite = false,
  }: ShortenOfferCardWithLikeProps,
) => {
  const { slug, isFavorite, status } = offer

  const isFavoritePost = hasAllFavorite || isFavorite

  const { handlePostLike } = useFavorite({ slug, isFavoritePost, offerSourceEndpoint })

  return (
    <div className={styles.shortOfferWithLike}>
      <ShortenOfferCard idPrefix={idPrefix} offer={offer}/>
      <div className={styles.addFavoriteButton}>
        { (status === OfferStatus.active || isFavoritePost) && (
          <FavoriteRoundedControl
            id={generateIdWithPrefix(idPrefix, PrefixAction.Like, offer.slug)}
            handleFavoriteClick={handlePostLike}
            isActive={isFavoritePost}
            isTransparent
          />)}
      </div>
    </div>
  )
}

export default ShortenOfferCardWithLike
