import { createContext, PropsWithChildren, useContext, useState } from 'react'

import { emptyFunction } from 'src/constants/function.constants'
import { ContextModalType } from 'src/models/modal.model'

interface IContextModalData {
  modalType: ContextModalType | null;
  onClose?: (() => void) | null;
  isNonFullscreen?: boolean;
}

interface IContextModalsContext {
  contextModalData: IContextModalData;
  setContextModal: (data: IContextModalData) => void;
}

const initialState = {
  contextModalData: {
    modalType: null,
    onClose: null,
  },
  setContextModal: emptyFunction,
}

const contextModalsContext = createContext<IContextModalsContext>(initialState)

export const ContextModalsProvider = ({ children }: PropsWithChildren) => {
  const [currentModal, setCurrentModal] = useState<IContextModalData>(initialState.contextModalData)

  return (
    <contextModalsContext.Provider value={{ contextModalData: currentModal, setContextModal: setCurrentModal }}>
      {children}
    </contextModalsContext.Provider>
  )
}

export const useModalsContext = () => useContext(contextModalsContext)
