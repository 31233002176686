import classNames from 'classnames'
import { ReactNode } from 'react'

import IconButton from 'src/components/button/IconButton'
import RegularButton, { IRegularButtonProps } from 'src/components/button/RegularButton'
import Heading from 'src/components/heading/Heading'
import Paragraph from 'src/components/paragraph/Paragraph'
import { ID } from 'src/constants/id.constants'

import styles from './mobileModal.module.scss'

interface IModalTopBarProps {
  handleClose?: () => void;
  goBack?: () => void;
  children: ReactNode;
  title?: string;
  subheading?: string;
  headingButtonProps?: IRegularButtonProps;
  primaryButtonProps?: IRegularButtonProps;
  secondaryButtonProps?: IRegularButtonProps;
}

const ModalContent = ({
  goBack,
  handleClose,
  children,
  title,
  headingButtonProps,
  primaryButtonProps,
  secondaryButtonProps,
  subheading,
}: IModalTopBarProps) => (
  <div className={classNames(
    styles.contentContainer,
    { 'fixed-button-container': primaryButtonProps && !secondaryButtonProps },
    { 'two-fixed-buttons-container': secondaryButtonProps },
  )}
  >
    {(goBack || title || headingButtonProps || handleClose) && (
      <div>
        <div className={styles.heading}>
          <div className={styles.heading}>
            {goBack && (
              <IconButton
                id={ID.modalBackButton}
                iconName='arrow-left'
                onClick={goBack}
              />
            )}
            { title && <Heading level='h2' className='label-20' label={title}/>}
          </div>
          {headingButtonProps && (
            <RegularButton {...headingButtonProps}/>
          )}
          {handleClose && (
            <div className={styles.close}>
              <IconButton iconName='close-cross' size={24} onClick={handleClose} id={ID.modalCloseButton}/>
            </div>
          )}
        </div>
        {subheading && <Paragraph text={subheading} className={classNames('supportive-14', styles.subheading)}/>}
      </div>
    )}
    <div className={styles.contentBody}>
      { children }
    </div>
    {(primaryButtonProps || secondaryButtonProps) && (
      <div className={classNames(
        styles.buttonsContainer,
        { 'fixed-modal-button': primaryButtonProps && !secondaryButtonProps },
        { 'two-fixed-buttons-modal': secondaryButtonProps },
      )}
      >
        {primaryButtonProps && (
          <RegularButton
            {...primaryButtonProps}
          />)}
        {secondaryButtonProps && (
          <RegularButton
            {...secondaryButtonProps}
          />)}
      </div>
    )}
  </div>
)

export default ModalContent
