import React, { memo } from 'react'

import { OnlineStatus } from 'src/components/onlineStatus/OnlineStatus'
import { POINT } from 'src/constants/general.constants'
import YomeURL from 'src/constants/navigate.constants'
import { usePriceFormatter } from 'src/hooks/price.hooks'
import { IChat } from 'src/models/messenger.model'

import ChatHeaderContentWrapper from './ChatHeaderContentWrapper'
import ChatHeaderOfferTitle from './ChatHeaderOfferTitle'
import ChatHeaderUserName from './ChatHeaderUserName'

import styles from './styles.module.scss'

export interface IChatHeaderContentProps {
  chat: IChat;
}

const ChatHeaderContent = ({
  chat: { receiverInfo, isReceiverOnline, offer: { price, title, slug, category } },
}: IChatHeaderContentProps) => {
  const formatPrice = usePriceFormatter()
  const linkToOffer = YomeURL.offer(category, slug)

  return (
    <ChatHeaderContentWrapper linkToOffer={linkToOffer}>
      <div className={styles.itemInfo}>
        <div className={styles.userInfo}>
          <ChatHeaderUserName receiver={receiverInfo}/>
          {isReceiverOnline && <OnlineStatus hasOnlineLabel/>}
        </div>
        <div className={styles.postInfo}>
          <ChatHeaderOfferTitle title={title} linkToOffer={linkToOffer} />
          {POINT}
          <span>
            {formatPrice(price)}
          </span>
        </div>
      </div>
    </ChatHeaderContentWrapper>
  )
}

export default memo(ChatHeaderContent)
