import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { Field, useFormState } from 'react-final-form'

import { MAX_ITEMS_IN_ROW, MAX_SUGGESTIONS_DISPLAY } from 'src/constants/newOffer.constants'
import { CATEGORY_PATH_FIELD_NAME } from 'src/models/post.model'
import { useCategorySearchSuggestions } from 'src/utils/category.utils'
import { getPathToCurrentCategory } from 'src/yome-categories-fields-config/utils/category.utils'

import CategorySearchResults from '../categorySearchResults/CategorySearchResults'

import styles from './categorySearchList.module.scss'

interface IShortCategoryListProps {
  onCategoryChange: (path: string[]) => void;
  searchFieldName: string;
}

const CategorySearchList = ({ onCategoryChange, searchFieldName }: IShortCategoryListProps) => {
  const { values } = useFormState()

  const [suggestions, setSuggestions] = useState<string[]>([])

  const categorySearchSuggestions = useCategorySearchSuggestions()

  useEffect(() => {
    setSuggestions(categorySearchSuggestions(values.searchQuery.trim(), MAX_SUGGESTIONS_DISPLAY))
  }, [categorySearchSuggestions, onCategoryChange, searchFieldName, values.searchQuery])

  const suggestionsMemo = useMemo(() => suggestions.map((item) => getPathToCurrentCategory(item)),
    [suggestions, values.searchQuery])

  const searchingItems = (
    <CategorySearchResults
      categoryPathList={suggestionsMemo}
      submitCategory={onCategoryChange}
      searchFieldName={searchFieldName}
    />)

  return (
    <Field
      name={CATEGORY_PATH_FIELD_NAME}
      render={() => (
        <div className={classNames({ [styles.grid]: suggestions.length < MAX_ITEMS_IN_ROW })}>
          {searchingItems}
        </div>
      )
      }
    >
    </Field >
  )
}

export default CategorySearchList
