import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  FormImageUpload,
  FormInput,
  FormPhoneField,
  FormPriceField,
  FormRadio,
  FormSelectDropdown,
  FormTextArea,
  FormToggle,
} from 'src/components/form'
import formStyles from 'src/components/form/form.module.scss'
import FormAutoRenewalToggle from 'src/components/form/FormAutoRenewalToggle/FormAutoRenewalToggle'
import FormCategorySelect from 'src/components/form/FormSelect/FormCategorySelect'
import FormMap from 'src/components/map/FormMap'
import Paragraph from 'src/components/paragraph/Paragraph'
import { MAX_OFFER_IMAGE_SIZE, SUPPORTING_TEXT } from 'src/constants/form.constants'
import { I18N_POST_DATA } from 'src/constants/i18n.constants'
import { concatId, ID } from 'src/constants/id.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { useFieldNameTranslation } from 'src/hooks/form.hooks'
import { FieldType, IFieldConfig, MainCategories } from 'src/yome-categories-fields-config/models/fields.model'

import styles from './offerForm.module.scss'

interface IFieldConstructorProps {
  fieldConfig: IFieldConfig;
  publicId: string;
  mainCategory: MainCategories;
}

const FieldByType = ({
  fieldConfig,
  publicId,
  mainCategory,
}: IFieldConstructorProps) => {
  const {
    type,
    options,
    fields,
    defaultValue,
    name,
    info,
    placeholder,
    tooltip,
    showCounter,
    validation,
  } = fieldConfig
  const { t } = useTranslation()
  const { isDesktop } = useViewport()

  if (!type) {
    throw new Error(`No type for field ${name} in field config`)
  }

  const fieldProps = {
    name,
    placeholder,
    id: concatId(ID.formFieldInputForm, name),
    showCounter,
    maxSymbols: validation?.max,
    fieldWrapperProps: {
      name,
      info,
      tooltip,
    },
  }

  if (type === FieldType.text) {
    return <FormInput {...fieldProps} />
  }

  if (type === FieldType.textarea) {
    return <FormTextArea {...fieldProps} />
  }

  // select or radio (controls with choice)
  if ([FieldType.select, FieldType.radio].includes(type)) {
    if (!options || !defaultValue) {
      throw new Error(`No options/default for field ${name} in field config`)
    }

    // TODO: get rid of options (move them into the component)
    const optionsObj: Record<string, string> = options.reduce((obj, opt) => (
      {
        ...obj,
        [opt]: t(`${I18N_POST_DATA}.${name}.${opt}`),
      }
    ), {})

    if (type === FieldType.select) {
      const MAX_MOBILE_OPTIONS = 6 // if for mobile number of options > 6 - use fullscreen dropdown

      return (
        <div className={styles.halfWidthFieldLayout}>
          <FormSelectDropdown
            {...fieldProps}
            isFullSize={!isDesktop && options.length >= MAX_MOBILE_OPTIONS}
            options={optionsObj}
            hasIcon={name === 'color'}
          />
        </div>
      )
    }

    if (type === FieldType.radio) {
      const optionsInfo = fieldConfig.optionsInfo ? (
        fieldConfig.optionsInfo.map((opt) => t(opt))
      ) : undefined

      if (isDesktop) {
        return (
          <FormRadio
            {...fieldProps}
            options={optionsObj}
            optionsInfo={optionsInfo}
          />
        )
      }

      return (
        <FormSelectDropdown
          {...fieldProps}
          options={optionsObj}
          hasIcon={name === 'color'}
        />
      )
    }
  }

  if (type === FieldType.checkbox) {
    return <FormToggle {...fieldProps} label={`${I18N_POST_DATA}.${name}`} />
  }

  if (type === FieldType.complex) {
    if (!fields) {
      throw new Error(`No fields for complex field ${name} in field config`)
    }

    return (
      <div className={styles.subfields}>
        {Object.keys(fields).map((subfield) => (
          <FieldByType key={subfield} publicId={publicId} fieldConfig={fields[subfield]} mainCategory={mainCategory}/>
        ))}
      </div>
    )
  }

  if (type === FieldType.categorySelect) {
    return <FormCategorySelect name={name} />
  }

  if (type === FieldType.file) {
    return <FormImageUpload {...fieldProps} publicId={publicId} maxSize={MAX_OFFER_IMAGE_SIZE} />
  }

  if (type === FieldType.location) {
    return <FormMap {...fieldProps} />
  }

  if (type === FieldType.phone) {
    return (
      <div className={styles.halfWidthFieldLayout}>
        <FormPhoneField {...fieldProps} />
      </div>
    )
  }

  if (type === FieldType.price) {
    return <FormPriceField {...fieldProps} />
  }

  if (type === FieldType.isAutoRenewal) {
    return (
      <FormAutoRenewalToggle
        {...fieldProps}
        supportingText={SUPPORTING_TEXT[mainCategory]}
        label={`${I18N_POST_DATA}.${name}`}
      />)
  }

  return null
}

const FieldConstructor = ({ fieldConfig, ...other }: IFieldConstructorProps) => {
  const { type, optional, mainLabelInfo } = fieldConfig

  const translateFieldName = useFieldNameTranslation()

  return (
    <div className={classNames(styles.postField, { [styles.mapField]: type === FieldType.location })}>
      <div>
        <legend className={styles.postFieldLabel}>
          {translateFieldName(fieldConfig, other.mainCategory)}
          {!optional && (
            <span className={classNames('label-14', styles.required)}>
              *
            </span>
          )}
        </legend>
        {mainLabelInfo && (
          <Paragraph className={classNames('caption-14', formStyles.fieldInfo)}>
            {mainLabelInfo}
          </Paragraph>
        )}
      </div>
      <FieldByType {...other} fieldConfig={fieldConfig} />
    </div>
  )
}

export default FieldConstructor
