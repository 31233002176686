import { IChat } from 'src/models/messenger.model'

import ChatHeader from '../chatHeader/ChatHeader'
import ChatHistory from '../chatHistory/ChatHistory'
import MessageForm from '../chatMessageForm/ChatMessageForm'
import NoDialog from '../noDialog/NoDialog'

import styles from './styles.module.scss'

type ChatDialogProps = {
  currentChat: IChat | undefined;
}

const ChatDialog = ({ currentChat } : ChatDialogProps) => {
  if (!currentChat) {
    return <NoDialog />
  }

  return (
    <div className={styles.wrapperChanel}>
      <ChatHeader chat={currentChat} />
      <ChatHistory currentChat={currentChat}/>
      <MessageForm chat={currentChat}/>
    </div>
  )
}

export default ChatDialog
