import { Subject } from 'src/constants/id.constants'

import { I18N_SEARCH_FILTER } from '../../constants/i18n.constants'
import { FieldType, IFieldConfig } from '../../yome-categories-fields-config/models/fields.model'
import FilterCategory from './filterCategory/FilterCategory'
import FilterCheckboxField from './FilterCheckboxField'
import FilterPriceField from './FilterPriceField'
import FilterRadioField from './FilterRadioField'
import FilterSearchDescription from './FilterSearchDescription'
import FilterToggle from './FilterToggle'

import styles from './filter.module.scss'

interface IFilterConstructorProps {
  fieldConfig: IFieldConfig;
}

const FilterFieldConstructor = ({ fieldConfig }: IFilterConstructorProps) => {
  const { filter, name, options, fields } = fieldConfig

  if (!filter) {
    throw new Error(`No filter config for field ${name}`)
  }

  if (filter.type === FieldType.category) {
    return (
      <FilterCategory name={name}/>
    )
  }

  if (filter.type === FieldType.descriptionSearch) {
    return (
      <FilterSearchDescription name={name} />
    )
  }

  if (filter.type === FieldType.price) {
    return (
      <FilterPriceField name={name}/>
    )
  }

  if (filter.type === FieldType.toggle) {
    return (
      <FilterToggle name={name}/>
    )
  }

  if (filter.type === FieldType.complex) {
    if (!fields) {
      throw new Error(`No fields for complex field ${name} in field-config`)
    }

    const subfields = Object.keys(fields)

    return (
      <div className={styles.subfields}>
        { subfields.map((subfield) => {
          const props = {
            fieldConfig: fields[subfield],
          }

          return (
            <FilterFieldConstructor {...props} key={subfield}/>
          )
        })}
      </div>)
  }

  if (!options) {
    throw new Error(`No options for field ${name}`)
  }

  if (filter.type === FieldType.checkbox) {
    return (
      <FilterCheckboxField
        title={`${I18N_SEARCH_FILTER}.${name}.title`}
        fieldName={name}
        options={options}
        isTwoColumns={filter.isTwoColumns}
        showLabelIcon={name === 'color'}
      />
    )
  }

  if (filter.type === FieldType.radio) {
    return (
      <FilterRadioField
        id={Subject.Filter}
        title={`${I18N_SEARCH_FILTER}.${name}.title`}
        options={options}
        name={name}
        btnLabel={`${I18N_SEARCH_FILTER}.${name}.btn`}
      />
    )
  }

  throw new Error(`Unexpected field type of field ${name}`)
}

export default FilterFieldConstructor
