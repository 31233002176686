import { memo } from 'react'
import { Link } from 'react-router-dom'

import PostAvatar from 'src/components/avatar/PostAvatar'
import IconButton from 'src/components/button/IconButton'
import { ID } from 'src/constants/id.constants'
import YomeURL from 'src/constants/navigate.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { AvatarSizes } from 'src/models/upload.model'

import { IChat } from '../../../../models/messenger.model'
import ChatHeaderContent from './ChatHeaderContent'

import styles from './styles.module.scss'

interface IChatHeaderProps {
  chat: IChat;
}

const ChatHeader = ({ chat }: IChatHeaderProps) => {
  const { isDesktop } = useViewport()
  const { publicId, photo } = chat.offer

  return (
    <div className={styles.header}>
      <div className={styles.navigation}>
        {!isDesktop && (
          <IconButton
            iconName="back-button"
            linkTo={YomeURL.messages}
            id={ID.messengerBackButton}
            size={32}
          />
        )}
        <div className={styles.content}>
          <Link to={YomeURL.userPage(chat.receiverInfo.id)} className={styles.postImage}>
            <PostAvatar
              avatarType={isDesktop ? AvatarSizes.tiny : AvatarSizes.xtiny}
              image={photo}
              folder={publicId}
            />
          </Link>
          <ChatHeaderContent chat={chat} />
        </div>
        {/* <div className={styles.tooltip}>
          <ChatHeaderDropdown
            receiverInfo={chat.receiverInfo}
            link={linkToOffer}
            title={chat.postInfo.title}
          />
        </div> */}
      </div>
    </div>
  )
}

export default memo(ChatHeader)
