import { Link } from 'react-router-dom'

import YomeURL from 'src/constants/navigate.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { IChatUser } from 'src/models/messenger.model'

interface IChatHeaderUserName {
  receiver: IChatUser;
}

const ChatHeaderUserName: React.FC<IChatHeaderUserName> = ({ receiver }) => {
  const { id, name } = receiver
  const { isDesktop } = useViewport()

  return isDesktop
    ? (
      <Link to={YomeURL.userPage(id)}>
        {name}
      </Link>
    ) : (
      <>
        {name}
      </>
    )
}

export default ChatHeaderUserName
