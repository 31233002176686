import classNames from 'classnames'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import SocialButton from 'src/components/button/socialButton/SocialButton'
import { I18N_AUTH } from 'src/constants/i18n.constants'
import { OAuthProviders } from 'src/models/auth.model'
import YomeURL from 'src/constants/navigate.constants'

import styles from './socialSignin.module.scss'

const SOCIALS = [{
  name: OAuthProviders.google,
  oAuthLink: YomeURL.oauthGoogle,
}, {
  name: OAuthProviders.facebook,
  oAuthLink: YomeURL.oauthFacebook,
}, {
  name: OAuthProviders.apple,
  oAuthLink: YomeURL.oauthApple,
}]

const SocialSignin = () => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const currentUrl = `${pathname}${search}`

  return (
    <section className={styles.socials}>
      <div className={styles.dividerHeader}>
        <span className={styles.divider}></span>
        <p className={classNames(styles.dividerText, 'bodySizeM')}>
          {t(`${I18N_AUTH}.social.signup`)}
        </p>
        <span className={styles.divider}></span>
      </div>
      <ul className={styles.socialIcons}>
        {SOCIALS.map(({ name, oAuthLink }) => (
          <li key={name}>
            <SocialButton
              socialName={name}
              socialLink={`${oAuthLink}?from=${encodeURIComponent(currentUrl)}`}
            />
          </li>))}
      </ul>
    </section>
  )
}

export default memo(SocialSignin)
