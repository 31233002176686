/* eslint-disable max-len */
enum Section {
  Page = 'page',
  Messenger = 'messenger',
  UserPage = 'userPage',
  ProfilePage = 'profilePage',
  FavoritesPage = 'favoritesPage',
  PostPage = 'postPage',
  Recommended = 'recommended',
  MyItems = 'myItems',
  Breadcrumbs = 'breadcrumbs',
  TopBar = 'topBar',

  Footer = 'footer',

  EditItem = 'editItem',
  EditDraft = 'editDraft',

  Header = 'header',
  HeaderDropdown = 'headerDropdown',

  Chip = 'chip',
  SearchBar = 'searchBar',
  SearchBarFullScreen = 'searchBarFullScreen',
  SearchResult = 'searchResult',
  SearchFilter = 'searchFilter',
  SupportPage = 'supportPage',
  Similar = 'similar',

  SavedSearch = 'savedSearch',

  Modal = 'modal',
  SignupModal = 'signupModal',
  LoginModal = 'loginModal',
  ResetModal = 'resetModal',
  ChangePasswordModal = 'changePasswordModal',
  CityModal = 'cityModal',
  ArchiveModal = 'archiveModal',
  ReportModal = 'reportModal',
  CookieModal = 'cookieModal',
  CookieManage = 'cookieManage',
  VerifyPhoneModal = 'verifyPhoneModal',
  BadgeModal = 'badgeModal',

  ProfileSetting = 'profileSetting',
  FormField = 'formField',

  UserCard = 'userCard',
  NewItem = 'newItem',

  Toast = 'toast',

  Legal = 'legal',

  LargeToggle = 'largeToggle',
  NotFound = 'notFound',

  ViewHistoryCardStack = 'viewHistoryCardStack',
  FeatureAnavailable = 'featureUnavailable',
  FavoriteCardStack = 'favoriteCardStack',
  FreeCardStack = 'freeCardStack',
  LastPublicationCardStack = 'lastPublicationCardStack',

  ForBusiness = 'forBusiness',
  Advertising = 'advertising',

  ShowcaseArea = 'showCaseArea',

  OfferModeration = 'offerModeration',
  UserModeration = 'userModeration',
}

enum Action {
  Scroll = 'Scroll',
  Prefix = 'Prefix',
  Button = 'Button',
  Input = 'Input',
  LinkTo = 'LinkTo',
  Select = 'Select',
  Checkbox = 'Checkbox',
  Toggle = 'Toggle',
  Show = 'Show',
  Like = 'Like',
  Share = 'Share',
  Report = 'Report',
  Save = 'Save',
  Sort = 'Sort',
  Reset = 'Reset',
  Submit = 'Submit',
  Archive = 'Archive',
  Cancel = 'Cancel',
  Edit = 'Edit',
  Search = 'Search',
  Subscribe = 'Subscribe',
  Suggest = 'Suggest',
  Post = 'Post',
  Accept = 'Accept',
  Reject = 'Reject',
  Send = 'Send',
  Publish = 'Publish',
  Delete = 'Delete',
  Promote = 'Promote',
  Attach = 'Attach',
  Open = 'Open',
  Close = 'Close',
  Back = 'Back',
  Add = 'Add',
  Display = 'Display',
  Apply = 'Apply',
  Clear = 'Clear',
  LearnMore = 'LearnMore',
  Launch = 'Launch',
  GetStarted = 'GetStarted',
  Dropdown = 'Dropdown',
  Block = 'Block',
  Verify = 'Verify',
  Helpful = 'Helpful',
  Unhelpful = 'Unhelpful',
  Sms = 'Sms',
  Call = 'Call',
  Resend = 'Resend',
  Translate = 'Translate',
}

enum Subject {
  MainPage = 'MainPage',
  Messenger = 'Messenger',
  Profile = 'Profile',
  Favorite = 'Favorite',
  NewOffer = 'NewOffer',
  Language = 'Language',
  Login = 'Login',
  Logout = 'Logout',
  Notifications = 'Notifications',
  ForgotPassword = 'ForgotPassword',
  LoginModal = 'LoginModal',
  SignupModal = 'SignupModal',
  SocialLogin = 'SocialLogin',
  Email = 'Email',
  Username = 'Username',
  ChatFilter = 'ChatFilter',
  ChatRoomActions = 'ChatRoomActions',
  Message = 'Message',
  Search = 'Search',
  Password = 'Password',
  NewPassword = 'NewPassword',
  PasswordConfirm = 'PasswordConfirm',
  PasswordReset = 'PasswordReset',
  Category = 'Category',
  Post = 'Post',
  Draft = 'Draft',
  AvatarDropdown = 'AvatarDropdown',
  City = 'City',
  Signup = 'Signup',
  Form = 'Form',
  MoreFilters = 'MoreFilters',
  Review = 'Review',
  Details = 'Details',
  Map = 'Map',
  MorePosts = 'MorePosts',
  UserOffers = 'UserOffers',
  Result = 'Result',
  Filters = 'Filters',
  Filter = 'Filter',
  Modal = 'Modal',
  Setting = 'Setting',
  User = 'User',
  Price = 'Price',
  Cookie = 'Cookie',
  CookiePolicy = 'CookiePolicy',
  Badge = 'Badge',
  MinPrice = 'MinPrice',
  MaxPrice = 'MaxPrice',
  SearchDescription = 'SearchDescription',
  Option = 'Option',
  Title = 'Title',
  Button = 'Button',
  Suggestion = 'Suggestion',
  Dots = 'Dots',
  About = 'About',
  Overlay = 'Overlay',
  Chip = 'Chip',
  File = 'File',
  Top = 'Top',
  Resend = 'Resend',
  Help = 'Help',
  PostTable = 'PostTable',
  PostLines = 'PostLines',
  PostMap = 'PostMap',
  RecentViews = 'RecentViews',
  LastPublications = 'LastPublications',
  ViewHistory = 'ViewHistory',
  Offer = 'Offer',
  FreeOffers = 'FreeOffers',
  Safety = 'Safety',
  ActiveOffer = 'ActiveOffer',
  InactiveOffer = 'InactiveOffer',
  ArchiveOffer = 'ArchiveOffer',
  DraftOffer = 'DraftOffer',
  EditModal = 'EditModal',
  OpenModal = 'OpenModal',
  Save = 'Save',
  NextStep = 'NextStep',
  BecomePartner = 'BecomePartner',
  RequestCall = 'RequestCall',
  RequestConsultation = 'RequestConsultation',
  Format = 'Format',
  Menu = 'Menu',
  Table = 'Table',
  Comment = 'Comment',
  Reason = 'Reason',
  Phone = 'Phone',
}

export const IDSection: Record<string, Section> = {
  [Section.Header]: Section.Header,
  [Section.SearchBar]: Section.SearchBar,
}

export type IdStructure = string // `${Section}${Action}${Subject}`

const assignIdType = <T extends Record<string, IdStructure>>(obj: T): T => obj

const ID = assignIdType({
  [`${Section.Header}${Action.LinkTo}${Subject.MainPage}`]: `${Section.Header}${Action.LinkTo}${Subject.MainPage}`,
  [`${Section.Header}${Action.LinkTo}${Subject.Messenger}`]: `${Section.Header}${Action.LinkTo}${Subject.Messenger}`,
  [`${Section.Header}${Action.LinkTo}${Subject.Profile}`]: `${Section.Header}${Action.LinkTo}${Subject.Profile}`,
  [`${Section.Header}${Action.LinkTo}${Subject.Favorite}`]: `${Section.Header}${Action.LinkTo}${Subject.Favorite}`,
  [`${Section.Header}${Action.LinkTo}${Subject.NewOffer}`]: `${Section.Header}${Action.LinkTo}${Subject.NewOffer}`,
  [`${Section.Header}${Action.LinkTo}${Subject.About}`]: `${Section.Header}${Action.LinkTo}${Subject.About}`,
  [`${Section.Header}${Action.Select}${Subject.Language}`]: `${Section.Header}${Action.Select}${Subject.Language}`,
  [`${Section.Header}${Action.LinkTo}${Subject.Login}`]: `${Section.Header}${Action.LinkTo}${Subject.Login}`,
  [`${Section.Header}${Action.LinkTo}${Subject.Notifications}`]: `${Section.Header}${Action.LinkTo}${Subject.Notifications}`,
  [`${Section.Header}${Action.LinkTo}${Subject.Logout}`]: `${Section.Header}${Action.LinkTo}${Subject.Logout}`,
  [`${Section.Header}${Action.LinkTo}${Subject.Option}`]: `${Section.Header}${Action.LinkTo}${Subject.Option}`,
  [`${Section.Header}${Action.Button}${Subject.Option}`]: `${Section.Header}${Action.Button}${Subject.Option}`,

  [`${Section.TopBar}${Action.Back}${Subject.Button}`]: `${Section.TopBar}${Action.Back}${Subject.Button}`,
  [`${Section.TopBar}${Action.Select}${Subject.Option}`]: `${Section.TopBar}${Action.Select}${Subject.Option}`,
  [`${Section.TopBar}${Action.Select}${Subject.Button}`]: `${Section.TopBar}${Action.Select}${Subject.Option}`,

  [`${Section.SupportPage}${Action.Attach}${Subject.Button}`]: `${Section.SupportPage}${Action.Attach}${Subject.Button}`,
  [`${Section.SupportPage}${Action.Submit}${Subject.Form}`]: `${Section.SupportPage}${Action.Submit}${Subject.Form}`,
  [`${Section.SupportPage}${Action.Input}${Subject.Username}`]: `${Section.SupportPage}${Action.Input}${Subject.Username}`,
  [`${Section.SupportPage}${Action.Input}${Subject.Email}`]: `${Section.SupportPage}${Action.Input}${Subject.Email}`,
  [`${Section.SupportPage}${Action.Input}${Subject.Message}`]: `${Section.SupportPage}${Action.Input}${Subject.Message}`,
  [`${Section.SupportPage}${Action.LinkTo}${Subject.MainPage}`]: `${Section.SupportPage}${Action.Input}${Subject.MainPage}`,
  [`${Section.SupportPage}${Action.Verify}${Subject.Email}`]: `${Section.SupportPage}${Action.Verify}${Subject.Email}`,

  [`${Section.Breadcrumbs}${Action.LinkTo}${Subject.MainPage}`]: `${Section.Breadcrumbs}${Action.LinkTo}${Subject.MainPage}`,
  [`${Section.Breadcrumbs}${Action.LinkTo}${Subject.Option}`]: `${Section.Breadcrumbs}${Action.LinkTo}${Subject.Option}`,

  [`${Section.HeaderDropdown}${Action.LinkTo}${Subject.Logout}`]: `${Section.HeaderDropdown}${Action.LinkTo}${Subject.Logout}`,

  [`${Section.LoginModal}${Action.LinkTo}${Subject.ForgotPassword}`]: `${Section.LoginModal}${Action.LinkTo}${Subject.ForgotPassword}`,
  [`${Section.LoginModal}${Action.LinkTo}${Subject.SignupModal}`]: `${Section.LoginModal}${Action.LinkTo}${Subject.SignupModal}`,
  [`${Section.LoginModal}${Action.LinkTo}${Subject.PasswordReset}`]: `${Section.LoginModal}${Action.LinkTo}${Subject.PasswordReset}`,
  [`${Section.LoginModal}${Action.Input}${Subject.Email}`]: `${Section.LoginModal}${Action.Input}${Subject.Email}`,
  [`${Section.LoginModal}${Action.Input}${Subject.Password}`]: `${Section.LoginModal}${Action.Input}${Subject.Password}`,
  [`${Section.LoginModal}${Action.Button}${Subject.Login}`]: `${Section.LoginModal}${Action.Button}${Subject.Login}`,
  [`${Section.LoginModal}${Action.Button}${Subject.Profile}`]: `${Section.LoginModal}${Action.Button}${Subject.Profile}`,
  [`${Section.LoginModal}${Action.LinkTo}${Subject.SocialLogin}`]: `${Section.LoginModal}${Action.LinkTo}${Subject.SocialLogin}`,
  [`${Section.LoginModal}${Action.LinkTo}${Subject.Login}`]: `${Section.LoginModal}${Action.LinkTo}${Subject.Login}`,

  [`${Section.ResetModal}${Action.Input}${Subject.Email}`]: `${Section.ResetModal}${Action.Input}${Subject.Email}`,
  [`${Section.ResetModal}${Action.Reset}${Subject.Password}`]: `${Section.ResetModal}${Action.Reset}${Subject.Password}`,

  [`${Section.CityModal}${Action.Submit}${Subject.City}`]: `${Section.SignupModal}${Action.Submit}${Subject.City}`,
  [`${Section.CityModal}${Action.Close}${Subject.Button}`]: `${Section.CityModal}${Action.Close}${Subject.Button}`,
  [`${Section.CityModal}${Action.Close}${Subject.Overlay}`]: `${Section.CityModal}${Action.Close}${Subject.Overlay}`,
  [`${Section.CityModal}${Action.Display}${Subject.Map}`]: `${Section.CityModal}${Action.Display}${Subject.Map}`,

  [`${Section.Modal}${Action.Close}${Subject.Button}`]: `${Section.Modal}${Action.Close}${Subject.Button}`,
  [`${Section.Modal}${Action.Back}${Subject.Button}`]: `${Section.Modal}${Action.Back}${Subject.Button}`,
  [`${Section.SignupModal}${Action.LinkTo}${Subject.LoginModal}`]: `${Section.SignupModal}${Action.LinkTo}${Subject.LoginModal}`,
  [`${Section.SignupModal}${Action.Input}${Subject.Email}`]: `${Section.SignupModal}${Action.Input}${Subject.Email}`,
  [`${Section.SignupModal}${Action.Input}${Subject.Username}`]: `${Section.SignupModal}${Action.Input}${Subject.Username}`,
  [`${Section.SignupModal}${Action.Input}${Subject.Password}`]: `${Section.SignupModal}${Action.Input}${Subject.Password}`,
  [`${Section.SignupModal}${Action.Button}${Subject.Signup}`]: `${Section.SignupModal}${Action.Button}${Subject.Signup}`,
  [`${Section.SignupModal}${Action.Button}${Subject.Resend}`]: `${Section.SignupModal}${Action.Button}${Subject.Resend}`,
  [`${Section.SignupModal}${Action.Button}${Subject.Login}`]: `${Section.SignupModal}${Action.Button}${Subject.Login}`,
  [`${Section.SignupModal}${Action.Close}${Subject.Button}`]: `${Section.SignupModal}${Action.Close}${Subject.Button}`,
  [`${Section.SignupModal}${Action.LinkTo}${Subject.Help}`]: `${Section.SignupModal}${Action.LinkTo}${Subject.Help}`,
  [`${Section.SignupModal}${Action.LinkTo}${Subject.MainPage}`]: `${Section.SignupModal}${Action.LinkTo}${Subject.MainPage}`,

  [`${Section.ArchiveModal}${Action.Archive}${Subject.Post}`]: `${Section.ArchiveModal}${Action.Archive}${Subject.Post}`,
  [`${Section.ArchiveModal}${Action.Cancel}${Subject.Modal}`]: `${Section.ArchiveModal}${Action.Cancel}${Subject.Modal}`,
  [`${Section.ArchiveModal}${Action.Prefix}${Subject.Post}`]: `${Section.ArchiveModal}${Action.Prefix}${Subject.Post}`,
  [`${Section.ArchiveModal}${Action.Input}${Subject.Reason}`]: `${Section.ArchiveModal}${Action.Input}${Subject.Reason}`,

  [`${Section.ReportModal}${Action.Cancel}${Subject.Button}`]: `${Section.ReportModal}${Action.Cancel}${Subject.Button}`,
  [`${Section.ReportModal}${Action.Submit}${Subject.Button}`]: `${Section.ReportModal}${Action.Submit}${Subject.Button}`,
  [`${Section.ReportModal}${Action.Input}${Subject.Comment}`]: `${Section.ReportModal}${Action.Input}${Subject.Comment}`,
  [`${Section.ReportModal}${Action.Input}${Subject.Reason}`]: `${Section.ReportModal}${Action.Input}${Subject.Reason}`,

  [`${Section.ChangePasswordModal}${Action.Input}${Subject.Password}`]: `${Section.ChangePasswordModal}${Action.Input}${Subject.Password}`,
  [`${Section.ChangePasswordModal}${Action.Input}${Subject.PasswordConfirm}`]: `${Section.ChangePasswordModal}${Action.Input}${Subject.PasswordConfirm}`,
  [`${Section.ChangePasswordModal}${Action.Submit}${Subject.Password}`]: `${Section.ChangePasswordModal}${Action.Submit}${Subject.Password}`,

  [`${Section.VerifyPhoneModal}${Action.Call}${Subject.Button}`]: `${Section.VerifyPhoneModal}${Action.Call}${Subject.Button}`,
  [`${Section.VerifyPhoneModal}${Action.Sms}${Subject.Button}`]: `${Section.VerifyPhoneModal}${Action.Sms}${Subject.Button}`,
  [`${Section.VerifyPhoneModal}${Action.Input}${Subject.Phone}`]: `${Section.VerifyPhoneModal}${Action.Input}${Subject.Phone}`,
  [`${Section.VerifyPhoneModal}${Action.Verify}${Subject.Button}`]: `${Section.VerifyPhoneModal}${Action.Verify}${Subject.Button}`,
  [`${Section.VerifyPhoneModal}${Action.Resend}${Subject.Button}`]: `${Section.VerifyPhoneModal}${Action.Resend}${Subject.Button}`,
  [`${Section.VerifyPhoneModal}${Action.LinkTo}${Subject.Phone}`]: `${Section.VerifyPhoneModal}${Action.LinkTo}${Subject.Phone}`,
  [`${Section.VerifyPhoneModal}${Action.Close}${Subject.Button}`]: `${Section.VerifyPhoneModal}${Action.Close}${Subject.Button}`,
  [`${Section.VerifyPhoneModal}${Action.LinkTo}${Subject.Login}`]: `${Section.VerifyPhoneModal}${Action.LinkTo}${Subject.Login}`,

  [`${Section.BadgeModal}${Action.Close}${Subject.Button}`]: `${Section.BadgeModal}${Action.Close}${Subject.Button}`,

  [`${Section.CookieModal}${Action.Accept}${Subject.Cookie}`]: `${Section.CookieModal}${Action.Accept}${Subject.Cookie}`,
  [`${Section.CookieModal}${Action.Reject}${Subject.Cookie}`]: `${Section.CookieModal}${Action.Reject}${Subject.Cookie}`,
  [`${Section.CookieModal}${Action.Close}${Subject.Cookie}`]: `${Section.CookieModal}${Action.Close}${Subject.Cookie}`,

  [`${Section.CookieManage}${Action.Open}${Subject.Modal}`]: `${Section.CookieModal}${Action.Open}${Subject.Modal}`,
  [`${Section.CookieManage}${Action.Close}${Subject.Modal}`]: `${Section.CookieModal}${Action.Close}${Subject.Modal}`,

  [`${Section.Messenger}${Action.Input}${Subject.ChatFilter}`]: `${Section.SignupModal}${Action.Input}${Subject.ChatFilter}`,
  [`${Section.Messenger}${Action.Input}${Subject.Message}`]: `${Section.SignupModal}${Action.Input}${Subject.Message}`,
  [`${Section.Messenger}${Action.Send}${Subject.Message}`]: `${Section.Messenger}${Action.Send}${Subject.Message}`,
  [`${Section.Messenger}${Action.Select}${Subject.Option}`]: `${Section.Messenger}${Action.Select}${Subject.Option}`,
  [`${Section.Messenger}${Action.Select}${Subject.Filters}`]: `${Section.Messenger}${Action.Select}${Subject.Filters}`,
  [`${Section.Messenger}${Action.Back}${Subject.Button}`]: `${Section.Messenger}${Action.Back}${Subject.Button}`,
  [`${Section.Messenger}${Action.Toggle}${Subject.ChatRoomActions}`]: `${Section.Messenger}${Action.Toggle}${Subject.ChatRoomActions}`,
  [`${Section.Messenger}${Action.Toggle}${Subject.ChatFilter}`]: `${Section.Messenger}${Action.Toggle}${Subject.ChatFilter}`,
  [`${Section.Messenger}${Action.Attach}${Subject.File}`]: `${Section.Messenger}${Action.Attach}${Subject.File}`,
  [`${Section.Messenger}${Action.LinkTo}${Subject.Help}`]: `${Section.Messenger}${Action.LinkTo}${Subject.Help}`,
  [`${Section.Messenger}${Action.Helpful}${Subject.Button}`]: `${Section.Messenger}${Action.Helpful}${Subject.Button}`,
  [`${Section.Messenger}${Action.Unhelpful}${Subject.Button}`]: `${Section.Messenger}${Action.Unhelpful}${Subject.Button}`,

  [`${Section.ProfilePage}${Action.Select}${Subject.AvatarDropdown}`]: `${Section.ProfilePage}${Action.Select}${Subject.AvatarDropdown}`,
  [`${Section.ProfilePage}${Action.Edit}${Subject.AvatarDropdown}`]: `${Section.ProfilePage}${Action.Edit}${Subject.AvatarDropdown}`,
  [`${Section.ProfilePage}${Action.LinkTo}${Subject.Profile}`]: `${Section.ProfilePage}${Action.LinkTo}${Subject.Profile}`,

  [`${Section.MyItems}${Action.Prefix}${Subject.ActiveOffer}`]: `${Section.MyItems}${Action.Prefix}${Subject.ActiveOffer}`,
  [`${Section.MyItems}${Action.Prefix}${Subject.InactiveOffer}`]: `${Section.MyItems}${Action.Prefix}${Subject.InactiveOffer}`,
  [`${Section.MyItems}${Action.Prefix}${Subject.ArchiveOffer}`]: `${Section.MyItems}${Action.Prefix}${Subject.ArchiveOffer}`,
  [`${Section.MyItems}${Action.Prefix}${Subject.DraftOffer}`]: `${Section.MyItems}${Action.Prefix}${Subject.DraftOffer}`,
  [`${Section.MyItems}${Action.Button}${Subject.NewOffer}`]: `${Section.MyItems}${Action.Button}${Subject.NewOffer}`,

  [`${Section.ProfileSetting}${Action.Input}${Subject.Username}`]: `${Section.ProfileSetting}${Action.Input}${Subject.Username}`,
  [`${Section.ProfileSetting}${Action.Input}${Subject.Email}`]: `${Section.ProfileSetting}${Action.Input}${Subject.Email}`,
  [`${Section.ProfileSetting}${Action.Submit}${Subject.Setting}`]: `${Section.ProfileSetting}${Action.Submit}${Subject.Setting}`,
  [`${Section.ProfileSetting}${Action.Cancel}${Subject.Setting}`]: `${Section.ProfileSetting}${Action.Cancel}${Subject.Setting}`,
  [`${Section.ProfileSetting}${Action.Edit}${Subject.Setting}`]: `${Section.ProfileSetting}${Action.Edit}${Subject.Setting}`,
  [`${Section.ProfileSetting}${Action.Back}${Subject.Button}`]: `${Section.ProfileSetting}${Action.Back}${Subject.Button}`,
  [`${Section.ProfileSetting}${Action.Close}${Subject.EditModal}`]: `${Section.ProfileSetting}${Action.Close}${Subject.EditModal}`,
  [`${Section.ProfileSetting}${Action.Button}${Subject.OpenModal}`]: `${Section.ProfileSetting}${Action.Button}${Subject.OpenModal}`,
  [`${Section.ProfileSetting}${Action.Button}${Subject.Save}`]: `${Section.ProfileSetting}${Action.Button}${Subject.Save}`,
  [`${Section.ProfileSetting}${Action.Edit}${Subject.Language}`]: `${Section.ProfileSetting}${Action.Edit}${Subject.Language}`,
  [`${Section.ProfileSetting}${Action.Input}${Subject.Password}`]: `${Section.ProfileSetting}${Action.Input}${Subject.Password}`,
  [`${Section.ProfileSetting}${Action.Input}${Subject.NewPassword}`]: `${Section.ProfileSetting}${Action.Input}${Subject.NewPassword}`,
  [`${Section.ProfileSetting}${Action.Input}${Subject.PasswordConfirm}`]: `${Section.ProfileSetting}${Action.Input}${Subject.PasswordConfirm}`,

  [`${Section.FormField}${Action.Input}${Subject.Form}`]: `${Section.FormField}${Action.Input}${Subject.Form}`,
  [`${Section.FormField}${Action.Select}${Subject.Form}`]: `${Section.FormField}${Action.Select}${Subject.Form}`,
  [`${Section.FormField}${Action.Button}${Subject.Form}`]: `${Section.FormField}${Action.Button}${Subject.Form}`,
  [`${Section.FormField}${Action.Close}${Subject.Form}`]: `${Section.FormField}${Action.Close}${Subject.Form}`,
  [`${Section.FormField}${Action.Clear}${Subject.Form}`]: `${Section.FormField}${Action.Clear}${Subject.Form}`,

  [`${Section.SearchBar}${Action.Input}${Subject.Search}`]: `${Section.SearchBar}${Action.Input}${Subject.Search}`,
  [`${Section.SearchBar}${Action.Search}${Subject.Post}`]: `${Section.SearchBar}${Action.Search}${Subject.Post}`,
  [`${Section.SearchBar}${Action.LinkTo}${Subject.MainPage}`]: `${Section.SearchBar}${Action.LinkTo}${Subject.MainPage}`,
  [`${Section.SearchBar}${Action.Select}${Subject.Category}`]: `${Section.SearchBar}${Action.Select}${Subject.Category}`,
  [`${Section.SearchBar}${Action.Select}${Subject.City}`]: `${Section.SearchBar}${Action.Select}${Subject.City}`,
  [`${Section.SearchBar}${Action.Select}${Subject.Suggestion}`]: `${Section.SearchBar}${Action.Select}${Subject.Suggestion}`,
  [`${Section.SearchBar}${Action.Cancel}${Subject.Button}`]: `${Section.SearchBar}${Action.Cancel}${Subject.Button}`,
  [`${Section.SearchBarFullScreen}${Action.Input}${Subject.Search}`]: `${Section.SearchBarFullScreen}${Action.Input}${Subject.Search}`,

  [`${Section.SearchFilter}${Action.Show}${Subject.MoreFilters}`]: `${Section.SearchFilter}${Action.Show}${Subject.MoreFilters}`,
  [`${Section.SearchFilter}${Action.Reset}${Subject.Filters}`]: `${Section.SearchFilter}${Action.Reset}${Subject.Filters}`,
  [`${Section.SearchFilter}${Action.Sort}${Subject.Result}`]: `${Section.SearchFilter}${Action.Sort}${Subject.Result}`,
  [`${Section.SearchFilter}${Action.Save}${Subject.Search}`]: `${Section.SearchFilter}${Action.Save}${Subject.Search}`,
  [`${Section.SearchFilter}${Action.Input}${Subject.MinPrice}`]: `${Section.SearchFilter}${Action.Input}${Subject.MinPrice}`,
  [`${Section.SearchFilter}${Action.Input}${Subject.MaxPrice}`]: `${Section.SearchFilter}${Action.Input}${Subject.MaxPrice}`,
  [`${Section.SearchFilter}${Action.Input}${Subject.SearchDescription}`]: `${Section.SearchFilter}${Action.Input}${Subject.SearchDescription}`,
  [`${Section.SearchFilter}${Action.Add}${Subject.Chip}`]: `${Section.SearchFilter}${Action.Add}${Subject.Chip}`,
  [`${Section.SearchFilter}${Action.Display}${Subject.PostMap}`]: `${Section.SearchFilter}${Action.Add}${Subject.Chip}`,
  [`${Section.SearchFilter}${Action.Display}${Subject.PostTable}`]: `${Section.SearchFilter}${Action.Add}${Subject.Chip}`,
  [`${Section.SearchFilter}${Action.Display}${Subject.PostLines}`]: `${Section.SearchFilter}${Action.Add}${Subject.Chip}`,
  [`${Section.SearchFilter}${Action.Select}${Subject.Category}`]: `${Section.SearchFilter}${Action.Select}${Subject.Category}`,
  [`${Section.SearchFilter}${Action.Show}${Subject.Filters}`]: `${Section.SearchFilter}${Action.Show}${Subject.Filters}`,
  [`${Section.SearchFilter}${Action.Show}${Subject.Offer}`]: `${Section.SearchFilter}${Action.Show}${Subject.Offer}`,
  [`${Section.SearchFilter}${Action.Apply}${Subject.Filter}`]: `${Section.SearchFilter}${Action.Apply}${Subject.Filter}`,
  [`${Section.SearchFilter}${Action.Clear}${Subject.Filter}`]: `${Section.SearchFilter}${Action.Clear}${Subject.Filter}`,
  [`${Section.SearchFilter}${Action.Close}${Subject.Button}`]: `${Section.SearchFilter}${Action.Close}${Subject.Button}`,

  [`${Section.Chip}${Action.Close}${Subject.Button}`]: `${Section.Chip}${Action.Close}${Subject.Button}`,

  [`${Section.PostPage}${Action.LinkTo}${Subject.Review}`]: `${Section.PostPage}${Action.LinkTo}${Subject.Review}`,
  [`${Section.PostPage}${Action.Toggle}${Subject.Details}`]: `${Section.PostPage}${Action.Button}${Subject.Details}`,
  [`${Section.PostPage}${Action.Toggle}${Subject.Map}`]: `${Section.PostPage}${Action.Toggle}${Subject.Map}`,
  [`${Section.PostPage}${Action.Report}${Subject.Post}`]: `${Section.PostPage}${Action.Report}${Subject.Post}`,
  [`${Section.PostPage}${Action.Show}${Subject.MorePosts}`]: `${Section.PostPage}${Action.Show}${Subject.MorePosts}`,
  [`${Section.PostPage}${Action.Subscribe}${Subject.User}`]: `${Section.PostPage}${Action.Subscribe}${Subject.User}`,
  [`${Section.PostPage}${Action.LinkTo}${Subject.UserOffers}`]: `${Section.PostPage}${Action.LinkTo}${Subject.UserOffers}`,
  [`${Section.PostPage}${Action.Suggest}${Subject.Price}`]: `${Section.PostPage}${Action.Suggest}${Subject.Price}`,
  [`${Section.PostPage}${Action.LinkTo}${Subject.Messenger}`]: `${Section.PostPage}${Action.LinkTo}${Subject.Messenger}`,
  [`${Section.PostPage}${Action.Like}${Subject.Post}`]: `${Section.PostPage}${Action.Like}${Subject.Post}`,
  [`${Section.PostPage}${Action.Share}${Subject.Post}`]: `${Section.PostPage}${Action.Share}${Subject.Post}`,
  [`${Section.PostPage}${Action.Publish}${Subject.Post}`]: `${Section.PostPage}${Action.Publish}${Subject.Post}`,
  [`${Section.PostPage}${Action.Edit}${Subject.Post}`]: `${Section.PostPage}${Action.Edit}${Subject.Post}`,
  [`${Section.PostPage}${Action.Delete}${Subject.Post}`]: `${Section.PostPage}${Action.Delete}${Subject.Post}`,
  [`${Section.PostPage}${Action.Promote}${Subject.Post}`]: `${Section.PostPage}${Action.Promote}${Subject.Post}`,
  [`${Section.PostPage}${Action.Archive}${Subject.Post}`]: `${Section.PostPage}${Action.Archive}${Subject.Post}`,
  [`${Section.PostPage}${Action.Post}${Subject.Title}`]: `${Section.PostPage}${Action.Post}${Subject.Title}`,
  [`${Section.PostPage}${Action.Back}${Subject.Button}`]: `${Section.PostPage}${Action.Back}${Subject.Button}`,
  [`${Section.PostPage}${Action.Translate}${Subject.Button}`]: `${Section.PostPage}${Action.Translate}${Subject.Button}`,

  [`${Section.UserPage}${Action.Prefix}${Subject.Post}`]: `${Section.UserPage}${Action.Prefix}${Subject.Post}`,
  [`${Section.UserPage}${Action.Subscribe}${Subject.User}`]: `${Section.UserPage}${Action.Subscribe}${Subject.User}`,
  [`${Section.UserPage}${Action.Sort}${Subject.Post}`]: `${Section.UserPage}${Action.Sort}${Subject.Post}`,

  [`${Section.FavoritesPage}${Action.Prefix}${Subject.Post}`]: `${Section.FavoritesPage}${Action.Prefix}${Subject.Post}`,
  [`${Section.FavoritesPage}${Action.Sort}${Subject.Result}`]: `${Section.FavoritesPage}${Action.Sort}${Subject.Result}`,
  [`${Section.FavoritesPage}${Action.Sort}${Subject.Post}`]: `${Section.FavoritesPage}${Action.Sort}${Subject.Post}`,

  [`${Section.SavedSearch}${Action.LinkTo}${Subject.Search}`]: `${Section.SavedSearch}${Action.LinkTo}${Subject.Search}`,
  [`${Section.SavedSearch}${Action.Delete}${Subject.Button}`]: `${Section.SavedSearch}${Action.Delete}${Subject.Button}`,

  [`${Section.SearchResult}${Action.Prefix}${Subject.Post}`]: `${Section.SearchResult}${Action.Prefix}${Subject.Post}`,

  [`${Section.Recommended}${Action.Prefix}${Subject.Post}`]: `${Section.Recommended}${Action.Prefix}${Subject.Post}`,
  [`${Section.Similar}${Action.Prefix}${Subject.Post}`]: `${Section.Similar}${Action.Prefix}${Subject.Post}`,

  [`${Section.ViewHistoryCardStack}${Action.Prefix}${Subject.Offer}`]: `${Section.ViewHistoryCardStack}${Action.Prefix}${Subject.Offer}`,
  [`${Section.ViewHistoryCardStack}${Action.LinkTo}${Subject.ViewHistory}`]: `${Section.ViewHistoryCardStack}${Action.LinkTo}${Subject.ViewHistory}`,

  [`${Section.FavoriteCardStack}${Action.LinkTo}${Subject.Favorite}`]: `${Section.FavoriteCardStack}${Action.LinkTo}${Subject.Favorite}`,
  [`${Section.FavoriteCardStack}${Action.Prefix}${Subject.Offer}`]: `${Section.FavoriteCardStack}${Action.Prefix}${Subject.Offer}`,

  [`${Section.FreeCardStack}${Action.Prefix}${Subject.Offer}`]: `${Section.FreeCardStack}${Action.Prefix}${Subject.Offer}`,
  [`${Section.FreeCardStack}${Action.Search}${Subject.FreeOffers}`]: `${Section.FreeCardStack}${Action.Search}${Subject.FreeOffers}`,

  [`${Section.LastPublicationCardStack}${Action.Prefix}${Subject.Offer}`]: `${Section.LastPublicationCardStack}${Action.Prefix}${Subject.Offer}`,
  [`${Section.LastPublicationCardStack}${Action.LinkTo}${Subject.Profile}`]: `${Section.LastPublicationCardStack}${Action.LinkTo}${Subject.Profile}`,

  [`${Section.EditItem}${Action.Edit}${Subject.Post}`]: `${Section.EditItem}${Action.Button}${Subject.Post}`,

  [`${Section.NewItem}${Action.Button}${Subject.Category}`]: `${Section.NewItem}${Action.Button}${Subject.Category}`,
  [`${Section.NewItem}${Action.Back}${Subject.Button}`]: `${Section.NewItem}${Action.Back}${Subject.Button}`,
  [`${Section.NewItem}${Action.Search}${Subject.Form}`]: `${Section.NewItem}${Action.Search}${Subject.Form}`,

  [`${Section.EditDraft}${Action.Save}${Subject.Draft}`]: `${Section.EditDraft}${Action.Save}${Subject.Draft}`,
  [`${Section.EditDraft}${Action.Post}${Subject.Draft}`]: `${Section.EditDraft}${Action.Post}${Subject.Draft}`,
  [`${Section.EditDraft}${Action.Back}${Subject.Button}`]: `${Section.EditDraft}${Action.Back}${Subject.Button}`,
  [`${Section.EditDraft}${Action.Open}${Subject.NextStep}`]: `${Section.EditDraft}${Action.Open}${Subject.NextStep}`,
  [`${Section.EditDraft}${Action.LinkTo}${Subject.ActiveOffer}`]: `${Section.EditDraft}${Action.LinkTo}${Subject.ActiveOffer}`,
  [`${Section.EditDraft}${Action.LinkTo}${Subject.MainPage}`]: `${Section.EditDraft}${Action.LinkTo}${Subject.MainPage}`,
  [`${Section.EditDraft}${Action.LinkTo}${Subject.DraftOffer}`]: `${Section.EditDraft}${Action.LinkTo}${Subject.DraftOffer}`,
  [`${Section.EditDraft}${Action.Prefix}${Subject.Offer}`]: `${Section.EditDraft}${Action.Prefix}${Subject.Offer}`,

  [`${Section.UserCard}${Action.LinkTo}${Subject.Review}`]: `${Section.UserCard}${Action.LinkTo}${Subject.Review}`,
  [`${Section.UserCard}${Action.Show}${Subject.Badge}`]: `${Section.UserCard}${Action.Show}${Subject.Badge}`,

  [`${Section.Toast}${Action.Close}${Subject.Button}`]: `${Section.Toast}${Action.Close}${Subject.Button}`,

  [`${Section.Footer}${Action.LinkTo}${Subject.Option}`]: `${Section.Footer}${Action.LinkTo}${Subject.Option}`,
  [`${Section.Footer}${Action.LinkTo}${Subject.MainPage}`]: `${Section.Footer}${Action.LinkTo}${Subject.MainPage}`,

  [`${Section.Legal}${Action.LinkTo}${Subject.Option}`]: `${Section.Legal}${Action.LinkTo}${Subject.Option}`,
  [`${Section.Legal}${Action.LinkTo}${Subject.Safety}`]: `${Section.Legal}${Action.LinkTo}${Subject.Safety}`,

  [`${Section.Page}${Action.Scroll}${Subject.Top}`]: `${Section.Page}${Action.Scroll}${Subject.Top}`,

  [`${Section.NotFound}${Action.LinkTo}${Subject.MainPage}`]: `${Section.NotFound}${Action.LinkTo}${Subject.MainPage}`,

  [`${Section.LargeToggle}${Action.LinkTo}${Subject.CookiePolicy}`]: `${Section.LargeToggle}${Action.LinkTo}${Subject.CookiePolicy}`,

  [`${Section.FeatureAnavailable}${Action.Close}${Subject.Modal}`]: `${Section.FeatureAnavailable}${Action.Close}${Subject.Modal}`,

  [`${Section.ForBusiness}${Action.LearnMore}${Subject.Button}`]: `${Section.ForBusiness}${Action.LearnMore}${Subject.Button}`,
  [`${Section.ForBusiness}${Action.Button}${Subject.Option}`]: `${Section.ForBusiness}${Action.Button}${Subject.Option}`,
  [`${Section.ForBusiness}${Action.Button}${Subject.BecomePartner}`]: `${Section.ForBusiness}${Action.Button}${Subject.BecomePartner}`,
  [`${Section.ForBusiness}${Action.Button}${Subject.RequestCall}`]: `${Section.ForBusiness}${Action.Button}${Subject.RequestCall}`,

  [`${Section.Advertising}${Action.Launch}${Subject.Button}`]: `${Section.Advertising}${Action.Launch}${Subject.Button}`,
  [`${Section.Advertising}${Action.GetStarted}${Subject.Button}`]: `${Section.Advertising}${Action.GetStarted}${Subject.Button}`,
  [`${Section.Advertising}${Action.Button}${Subject.RequestConsultation}`]: `${Section.Advertising}${Action.Button}${Subject.RequestConsultation}`,
  [`${Section.Advertising}${Action.Button}${Subject.Format}`]: `${Section.Advertising}${Action.Button}${Subject.Format}`,

  [`${Section.Legal}${Action.Back}${Subject.Button}`]: `${Section.Legal}${Action.Back}${Subject.Button}`,
  [`${Section.Legal}${Action.Toggle}${Subject.Table}`]: `${Section.Legal}${Action.Toggle}${Subject.Table}`,

  [`${Section.ShowcaseArea}${Action.LearnMore}${Subject.Button}`]: `${Section.ShowcaseArea}${Action.LearnMore}${Subject.Button}`,

  [`${Section.OfferModeration}${Action.Accept}${Subject.Button}`]: `${Section.OfferModeration}${Action.Accept}${Subject.Button}`,
  [`${Section.OfferModeration}${Action.Block}${Subject.Button}`]: `${Section.OfferModeration}${Action.Block}${Subject.Button}`,
  [`${Section.UserModeration}${Action.Block}${Subject.Button}`]: `${Section.OfferModeration}${Action.Block}${Subject.Button}`,
})

enum PrefixAction {
  Link = 'Link',
  Like = 'Like',
  Image = 'Image',
  Archive = 'Archive',
  Edit = 'Edit',
  Delete = 'Delete',
  Promote = 'Promote',
  Apply = 'Apply',
  Toggle = 'Toggle',
}

type ConcatedId = `${IdStructure}-${string}`

const concatId = (id: string, concatedValue: string): ConcatedId => {
  const idValue: IdStructure | undefined = (ID as Record<string, IdStructure>)[id]

  if (!idValue) {
    throw Error(`"${id}" is not in global ID map`)
  }

  return `${idValue}-${concatedValue}`
}

const generateIdWithPrefix = (
  prefix: IdStructure,
  action: PrefixAction,
  entityId: string,
) => `${prefix}${action}-${entityId}`

export {
  ID,
  PrefixAction,
  Subject,
  concatId,
  generateIdWithPrefix,
}
